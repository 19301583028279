import { Component, Inject, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadService } from '@sod/services/file-upload.service';
import { ApiUrl } from '../../constants';

@Component({
  selector: 'sod-image',
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
})
export class ImageComponent {
  @Input() idThumbnail?: number | null = 0;
  @Input() thumbnailUrl?: string | null = '';
  src: string = '';
  noSrc: boolean = false;

  constructor(
    public fileUploadService: FileUploadService,
    private domSanitizer: DomSanitizer,
    @Inject(ApiUrl.BASE_API_URL) private baseUrl: string,
  ) {  }

  ngOnInit(): void {
    if (!this.idThumbnail || this.idThumbnail < 0) {
      this.noSrc = true;
    }

    this.src = this.thumbnailUrl ? this.baseUrl+ `/api/resources/${this.thumbnailUrl}` : '';
  }
}
