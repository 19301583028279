import { Directive, ElementRef, inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { Principal } from '@sod/core/models';
import { STATE_TOKEN } from '@sod/store/app.state.model';
import { filter, map, mergeMap, Subject, switchMap, take, tap } from 'rxjs';

@Directive({
  selector: '[hasPermission]',
})
export class PermissionDirective implements OnInit {
  private store = inject(Store);
  userToken = this.store.select(STATE_TOKEN).pipe(
    filter(x=> !!x && !!x.token),
    map(x=> new Principal(x.token)));
  private permissions: string[] = [];
  private checkPemision = new Subject<string[]>();
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    this.checkPemision.pipe(
      switchMap(roles => this.userToken.pipe(map(token=> ({token , roles})))),
    ).subscribe(({ token, roles }) => {
      if(!token || !roles || roles.length === 0) {
        this.viewContainer.clear();
      }else if(roles.some(role => token.IsRole(role.toLowerCase()))) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      }else{
        this.viewContainer.clear();
      }
    })
  }

  ngOnInit() {
    this.checkPemision.next(this.permissions);
  }

  @Input()
  set hasPermission(val : string[]) {
    this.permissions = val;
    this.checkPemision.next(this.permissions);
  }

 }
