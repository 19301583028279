import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[sodClickOutSide]',
  standalone: true,
})
export class ClickOutSideDirective {
  @Output() clickedOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    console.log('Clicked inside:', clickedInside);

    if (!clickedInside) {
      this.clickedOutside.emit();
    }
  }
 }
