import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sod-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true
})
export class IconComponent {

  @HostBinding('style.-webkit-mask-image')
  private _path: string = '';

  @Input()
  public set path(filePath: string | null | undefined) {

    if (!filePath) {
      this._path = '';
      return;
    }
    this._path = `url("${filePath}")`;
  }
  @Input()
  public set src(filePath: string | null | undefined) {

    if (!filePath) {
      this._path = '';
      return;
    }
    this._path = `url("${filePath}")`;
  }
}
