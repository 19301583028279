import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgLetModule } from 'ng-let';
import { ButtonFilterComponent } from './components/button-filter/button-filter.component';
import { CustomPaginationComponent } from './components/custom-pagination/custom-pagination.component';
import { IconComponent } from './components/icon/icon.component';
import { ImageComponent } from './components/image/image.component';
import { PopupConfirmComponent } from './components/popup-confirm/popup-confirm.component';
import { StartRatingComponent } from './components/start-rating/start-rating.component';
import { StatusComponent } from './components/status/status.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { UploadVideoComponent } from './components/upload-video/upload-video.component';
import { ChineseInputDirective } from './directives/chinese-input.directive';
import { ContentHeaderDirective } from './directives/content-header.directive';
import { ControlRequiredDirective } from './directives/control-required.directive';
import { HeaderTittleDirective } from './directives/header-tittle.directive';
import { MarkDirective } from './directives/mark.directive';
import { OptionAllSelectDirective } from './directives/option-all-select.directive';
import { OptionsScrollDirective } from './directives/options-scroll.directive';
import { FileSizePipe } from './pipes/fileSize.pipe';
import { NgForFilterPipe } from './pipes/filter.pipe';
import { TimeSpanPipe } from './pipes/timeSpan.pipe';
import { PermissionDirective } from './directives/permission.directive';
import { CustormDatePipe } from './pipes/date.pipe';
import { SelectMutipleComponent } from './components/select-mutiple/select-mutiple.component';
import { TimeSpanDirective } from './directives/time-span.directive';
import { InputNumberDirective } from './directives/input-number.directive';
import { InputTimespanComponent } from './components/input-timespan/input-timespan.component';
import { InputFileSizeComponent } from './components/input-file-size/input-file-size.component';
import { SelectAutocompleteComponent } from './components/select-autocomplete/select-autocomplete.component';
import { SelectScrollComponent, SelectScrollModule } from './components/select-scroll/select-scroll.component';
import { TakePipe } from './pipes/take.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { InputChipComponent } from './components/input-chip/input-chip.component';

@NgModule({
  declarations: [ChineseInputDirective,
    ContentHeaderDirective,
    MarkDirective,
    UploadVideoComponent,
    PopupConfirmComponent,
    ControlRequiredDirective,
    ToastMessageComponent,
    StartRatingComponent,
    ImageComponent,

    OptionAllSelectDirective,
    PermissionDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogTitle,
    MatExpansionModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSelectModule,
    CustormDatePipe,
    TimeSpanPipe,
    FileSizePipe,
    NgForFilterPipe,
    MatProgressBarModule,
    MatSliderModule,
    MatCardModule,
    MatAutocompleteModule,
    MatTooltipModule,
    SelectMutipleComponent,
    TimeSpanDirective,
    StatusComponent,
    MatNativeDateModule,
    MatDatepickerModule,
    InputNumberDirective,
    InputTimespanComponent,
    InputFileSizeComponent,
    CustomPaginationComponent,
    HeaderTittleDirective,
    OptionsScrollDirective,
    SelectScrollModule,
    SelectAutocompleteComponent,
    InputChipComponent,
    NgLetModule,
    TakePipe,
    JoinPipe,
    IconComponent,
    ButtonFilterComponent,
  ], exports: [
    ContentHeaderDirective,
    ControlRequiredDirective,
    MarkDirective,
    StatusComponent,
    UploadVideoComponent,
    MatExpansionModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatToolbarModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDialogActions,
    MatInputModule,
    MatSortModule,
    MatDatepickerModule,
    MatDividerModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    IconComponent,
    MatDialogContent,
    ToastMessageComponent,
    MatChipsModule,
    StartRatingComponent,
    CustomPaginationComponent,
    TimeSpanPipe,
    FileSizePipe,
    MatProgressBarModule,
    MatSliderModule,
    ImageComponent,
    MatCardModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NgForFilterPipe,
    NgLetModule,
    OptionsScrollDirective,
    OptionAllSelectDirective,
    PermissionDirective,
    CustormDatePipe,
    SelectMutipleComponent,
    TimeSpanDirective,
    HeaderTittleDirective,
    InputNumberDirective,
    SelectScrollModule,
    InputTimespanComponent,
    InputFileSizeComponent,
    SelectAutocompleteComponent,
    InputChipComponent,
    ButtonFilterComponent,
    TakePipe,
    JoinPipe,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    MatDatepickerModule,
  ]
})
export class SharedModule { }
