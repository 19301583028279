import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'sodJoin',
  standalone: true,
})
export class JoinPipe<T> implements PipeTransform {

  transform(value: T[] | undefined, key: keyof T, separator = ', '): string {
    if(!value) return '';

    if (typeof value[0] === 'string') {
      return (value as unknown as string[]).join(separator);
    }

    return value.map(x => x[key]).filter(x => x).join(separator);
  }

}
