import { DatePipe } from '@angular/common';
import { Pipe, type PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import moment from 'moment';
@Pipe({
  name: 'sodDate',
  standalone: true,
})
export class CustormDatePipe implements PipeTransform {

  transform(date: Moment | string | Date | undefined | null, format: string = 'yyyy-MM-dd'): string | null {
    if (!date) return '';

    if( moment.isMoment(date)) return date.format(format);

    if(typeof date === 'string') {
      const d = moment(date);
      return d.format(format);
    }

    const newdate = new Date(date);
    return new DatePipe('en-US').transform(newdate, format);
  }

}
