import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input, input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'sod-input-file-size',
  standalone: true,
  imports: [ CommonModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule, ],
  templateUrl: './input-file-size.component.html',
  styleUrl: './input-file-size.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileSizeComponent),
      multi: true,
    },
  ],
})
export class InputFileSizeComponent implements ControlValueAccessor {
  @Input() unit : 'MB' | 'KB' = 'MB';
  private onChange = (value: any) => { };
  private onTouched = () => { };
  private isDisabled = false;
  data= new FormControl("");

  writeValue(value : number): void {
    const data = this.convertData(value);
    this.data.patchValue(data);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  convertData(value: number) {
    if (this.unit === 'MB') {
      return ""+ (+((value ?? 0) / 1024 / 1024).toFixed(2)); //MB
    }
   else{
      return "" +  (+((value ?? 0) / 1024).toFixed(2)) //KB
   }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.data.disable();
    } else {
      this.data.enable();
    }
  }
 }
