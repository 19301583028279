import { Pipe, PipeTransform } from "@angular/core";
import { DropdownHasSelect } from "@sod/models/index";
import { filterByText } from "../utility";


@Pipe({
    name: 'sodFilterngFor',
    standalone: true,
})


export class NgForFilterPipe implements PipeTransform {
  transform(items: DropdownHasSelect[], filter: string): any {
        if (!items || !filter || filter === '') {
            return items;
        }
        return filterByText(items, filter,['viewValue','value']);
    }
}
