import { Directive, Renderer2, TemplateRef } from '@angular/core';
import { HeaderService } from '@sod/services/header.service';

@Directive({
  selector: '[sodContentHeader]'
})
export class ContentHeaderDirective {

  constructor(public template: TemplateRef<any>, private renderer: Renderer2, private headerService: HeaderService) { }
  ngOnInit(): void {
    debugger
    const title = this.template.elementRef;
    this.headerService.setHeaderTemplate(title);
  }
}
