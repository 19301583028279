export function funcOrderBy<T>(a: T[keyof T], b: T[keyof T]): number {
  const aValue = typeof a === 'string' ? removeUnicode(a.toLowerCase()) : a;
  const bValue = typeof b === 'string' ? removeUnicode(b.toLowerCase()) : b;
  if (aValue == bValue) return 0;
  return aValue < bValue ? -1 : 1
}
export function funcOrderDesc<T>(a: T[keyof T], b: T[keyof T]): number {
  return funcOrderBy(b, a);
}

export function order<T>(data: T[], sortParam: string): T[] {
  if (!data) return []
  if (!sortParam) return data;
  const [field, predicate] = sortParam.split(' ');
  const fileSort = field as keyof T;
  const predicateSort = predicate as 'asc' | 'desc' | null;
  return orderBy(data, fileSort, predicateSort);
}

export function orderBy<T>(data: T[], fileSort: keyof T, predicate: null | 'asc' | 'desc' = null): T[] {
  if (!data) return []
  const functionOrder = predicate == 'desc' ? funcOrderDesc : funcOrderBy;
  return [...data.sort((a, b) => functionOrder<T>(a[fileSort], b[fileSort]))];
}

export function filterByText<T>(data: T[] | null, search: string | undefined, keys: (keyof T)[]): T[] {
  if (!data) return [];
  if (!search || typeof search !== "string") return data;
  const textSearch = search.toLocaleLowerCase();
  return data.filter(x => compareByText(x, textSearch, keys));
}

export function filterNotInclude<T, K extends keyof T>(data: T[] | null, exclude: (T[K])[], key: K): T[] {
  if (!data) return [];
  if (!exclude || exclude.length == 0) return data;
  return data.filter(x => !exclude.includes(x[key]));
}

export function compareByText<T>(data: T | null, search: string | undefined, keys: (keyof T)[]): boolean {
  if (!data) return false
  if (!search) return true;
  for (const key of keys) {
    const result = (data[key] || '').toString().toLocaleLowerCase();
    if (result.includes(search)) return true
  }
  return false;
}

function removeUnicode(str: string): string {
  return str.replace(/[^\x00-\x7F]/g, '');
}

export function getTimeLine(numbers: number[], step: number = 1, multiplier: number = 1000): (number[])[] {
  // get time line from array number
  // example: [1,1.2,1.3,5,6,7,8,10] => [[1,3],[5,8]]
  if(numbers.length == 0) return [];
  const result = [];
  let start = numbers[0];
  let end = numbers[0];
  for (let i = 1; i < numbers.length; i++) {
    if ((numbers[i] << 0) - (end << 0) <= step) { // round number
      end = numbers[i];
    }
    else {
      result.push([start * multiplier, end * multiplier]);
      start = numbers[i];
      end = numbers[i];
    }
  }
  result.push([start * multiplier, end * multiplier]);
  return result;

}
