import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, EventEmitter, forwardRef, inject, Input, OnDestroy, OnInit, Output, signal, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HeaderService } from '@sod/services/header.service';
import { ClickOutSideDirective } from '@sod/shared/directives/click-out-side.directive';
import { filterByText } from '@sod/shared/utility';
import { NgLetModule } from 'ng-let';
import { debounceTime, filter, map, Observable, startWith, Subject, take, takeUntil } from 'rxjs';
import { PageContentDirective } from "../../directives/pageContent.directive";

@Component({
  selector: 'sod-select-autocomplete',
  standalone: true,
  imports: [
    CommonModule, MatInputModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatAutocompleteModule,
    MatChipsModule, NgLetModule, MatIconModule, MatCheckboxModule, MatTooltipModule, ClickOutSideDirective,
    PageContentDirective
  ],
  templateUrl: './select-autocomplete.component.html',
  styleUrl: './select-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[id]': 'id',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAutocompleteComponent),
      multi: true,
    },
  ],
})
export class SelectAutocompleteComponent<T> implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() set allOptions(value: Observable<T[]> | null) {
    if (value) {
      this._options$ = value;
    }
    this.searchControl.setValue(null, { emitEvent: false });
    this.updateDisplay$.next();
  }
  @Input() set textValue(value: string | null | undefined) {

    if (typeof value === "string") {
      this.searchControl.setValue(value || null, { emitEvent: false });
      this._freeText.set(true);
    }
  }
  @Input() set customLabel(value: TemplateRef<any> | null | undefined) {
    if (value) {
      this._customLabel = value;
    }
  };

  @Input() set label(value: keyof T) {
    if (value) {
      this._labelSearch = [value];
    }
  };
  @Input() set labelSearch(value: (keyof T)[]) {
    if (value) {
      this._labelSearch = value;
    }
  };
  @Input() key!: keyof T;
  @Input() placeholder?: string;
  @Output() onSelected = new EventEmitter<T[keyof T] | null>();
  @Output() textValueChange = new EventEmitter<string | null | undefined>();

  @ViewChild('defaultTemplate', { static: true }) defaultTemplate!: TemplateRef<any>;
  readonly id = `sod-auto-complete-${SelectAutocompleteComponent.nextId++}`;
  static nextId = 0;
  private _customLabel: TemplateRef<any>;
  private _labelSearch: (keyof T)[] = [];
  private _suppendUpdateDisplay = signal(false);
  private _freeText = signal(false);
  protected _preValue: T[keyof T] | null = null;
  get labelSearch() {
    return this._labelSearch
  }
  get customLabel() {
    return this._customLabel || this.defaultTemplate
  }
  private _freeTextValue = signal<string | null>(null);
  protected _options$: Observable<T[]> = new Observable();
  protected _allOption: T[] = [];
  protected _value = signal<T[keyof T] | null>(null);
  protected value = computed(() => this._value());
  protected freeText = computed(() => this._freeText());
  protected searchControl = new FormControl<string | null>(null);
  protected hasData = computed(() => (this._dirty() || this._value() && this._allOption.length > 0) || !!this._freeTextValue());
  protected suppendUpdateDisplay = computed(() => this._suppendUpdateDisplay())

  _dirty = signal<boolean>(false);
  outFocus = new Subject<void>();
  updateDisplay$ = new Subject<void>();
  destroy$ = new Subject<void>();
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild('templateContainer', { read: ViewContainerRef }) templateContainer!: ViewContainerRef;
  @ViewChild('templateContainer', { read: ElementRef }) templateContainerElement!: ElementRef;
  toast = inject(HeaderService);
  protected onChange = (value: any) => { };
  protected onTouched = () => { };
  protected isDisabled = false;
  protected filteredOptions$: Observable<T[]> = this.searchControl.valueChanges.pipe(
    takeUntil(this.destroy$),
    startWith(''),
    debounceTime(300),
    map(value => this.filterOptions(value))
  );

  constructor() {
    this._customLabel = this.defaultTemplate;
    this.filteredOptions$
    this.outFocus.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.autocompleteTrigger.closePanel();
      if (this._preValue == this.value) {
        this.updateDisplay$.next();
      } else {
        this.updateDisplay$.next();
        this._preValue = null;
      }
    })

    effect(() => {
      const text = this._freeTextValue();
      this.searchControl.setValue(text, { emitEvent: false });
      this.textValueChange.emit(text);
    })
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOptionSelected(e: MatAutocompleteSelectedEvent) {
    this._value.set(e.option.value);
    const text = e.option._text?.nativeElement.textContent || '';
    this.searchControl.setValue(text, { emitEvent: false });
    this._suppendUpdateDisplay.set(true);
    this._dirty.set(true);

    this.onChange(this.value());
    this.onSelected.emit(this.value());

  }
  enterPress(text: string) {
    this.searchControl.setValue(text, { emitEvent: false });
    this.textValueChange.emit(text);
    this.outFocus.next();
  }


  renderTemplateToText(option: T | null) {
    if (!option) {
      // this._freeTextValue.set('');
      return;
    }

    const template = this.customLabel

    if (template && option) {
      this.templateContainer.clear();
      const embeddedView = this.templateContainer.createEmbeddedView(template, { option: option });

      setTimeout(() => {
        const textContent = this.templateContainerElement.nativeElement.previousSibling?.textContent || '';
        this._freeTextValue.set(textContent);
        this.templateContainer.clear();
        embeddedView.destroy();
        return textContent;
      }, 0);
    } else {
      // this._freeTextValue.set('');
    }
  }

  filterOptions(value: string | null | undefined): T[] {
    if (!value) return this._allOption;

    return filterByText(this._allOption, value, this._labelSearch)
  }

  ngOnInit(): void {
    this.updateDisplay$.pipe(
      takeUntil(this.destroy$),
      filter(() => !this.suppendUpdateDisplay())
    ).subscribe(() => {

      this._freeTextValue.set(null);
      const options = this.getTextDisplay();
      this.renderTemplateToText(options);
    })

  }

  getTextDisplay() {
    const selected = this.value;
    if (!selected || !this._allOption || this._allOption.length == 0) return null;

    const option = this._allOption.find(x => x[this.key] === selected);
    if (!option) return null;
    return option;
  }

  clear() {
    this._value.set(null);
    this.onChange(null);
    this.onSelected.emit(null);
    this.textValueChange.emit(null);
    this.searchControl.setValue(null, { emitEvent: false });
    this._freeTextValue.set(null);
    this._dirty.set(false);

  }

  onBlur() {

    this.outFocus.next();
    if (this._freeText()) {
      this._dirty.set(!!this.searchControl.value);
      this.textValueChange.emit(this.searchControl.value);
      return;
    }

  }


  onFocus() {
    this._preValue = this.value();
    this._suppendUpdateDisplay.set(false);
    this._dirty.set(false);
    if (!this._allOption || this._allOption.length == 0) {

      this.toast.disableLoading(true);
      this._options$.pipe(take(1)).subscribe(res => {
        this.toast.disableLoading(false);
        this._allOption = res;
        this.updateDisplay$.next();
        this.searchControl.setValue(null, { emitEvent: true });
      })
    }
  }
  // ControlValueAccessor Implementation
  writeValue(value: T[keyof T]): void {
    this._value.set(value);
    this.updateDisplay$.next();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
