import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'sodFileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {

  transform(value?: number | null, ...args: unknown[]): number {
    return (+((value ?? 0) / 1024 / 1024).toFixed(2))
  }
}
