import { FocusMonitor } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  booleanAttribute,
  effect,
  inject,
  input,
  model,
  signal,
  untracked,
  viewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormFieldControl
} from '@angular/material/form-field';
import { InputNumberDirective } from '@sod/shared/directives/input-number.directive';
import { Subject } from 'rxjs';


/** Data structure for holding telephone number. */
export class TimeSpan {
  constructor(
    public area: string,
    public exchange: string,
    public subscriber: string,
  ) { }
}

/** Custom `MatFormFieldControl` for telephone number input. */
@Component({
  selector: 'sod-input-timespan',
  templateUrl: 'input-timespan.component.html',
  styleUrl: 'input-timespan.component.scss',
  providers: [{ provide: MatFormFieldControl, useExisting: InputTimespanComponent }],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputNumberDirective, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTimespanComponent implements ControlValueAccessor, MatFormFieldControl<number>, OnDestroy {
  @Input() max: number | undefined | null = null;
  @Input() min: number | undefined | null = null;
  static nextId = 0;
  readonly areaInput = viewChild.required<HTMLInputElement>('area');
  readonly exchangeInput = viewChild.required<HTMLInputElement>('exchange');
  readonly subscriberInput = viewChild.required<HTMLInputElement>('subscriber');
  ngControl = inject(NgControl, { optional: true, self: true });

  readonly stateChanges = new Subject<void>();
  readonly touched = signal(false);
  readonly controlType = 'sod-input-timespan';
  readonly id = `sod-input-timespan-${InputTimespanComponent.nextId++}`;
  readonly _userAriaDescribedBy = input<string>('', { alias: 'aria-describedby' });
  readonly _placeholder = input<string>('', { alias: 'placeholder' });
  readonly _required = input<boolean, unknown>(false, {
    alias: 'required',
    transform: booleanAttribute,
  });
  readonly _disabledByInput = input<boolean, unknown>(false, {
    alias: 'disabled',
    transform: booleanAttribute,
  });
  readonly _value = model<TimeSpan | null>(null, { alias: 'value' });
  onChange = (_: any) => { };
  onTouched = () => { };

  protected readonly _formField = inject(MAT_FORM_FIELD, {
    optional: true,
  });

  private readonly _focused = signal(false);
  private readonly _disabledByCva = signal(false);
  private readonly _focusMonitor = inject(FocusMonitor);
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private _disabled = false;

  get focused(): boolean {
    return this._focused();
  }

  get empty() {
    const {
      value: { area, exchange, subscriber },
    } = this.parts;

    return !area && !exchange && !subscriber;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get userAriaDescribedBy() {
    return this._userAriaDescribedBy();
  }

  get placeholder(): string {
    return this._placeholder();
  }

  get required(): boolean {
    return this._required();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get value(): number | null {
    const raw = this._value();
    return this.timeSpanToSecond(raw);
  }
  private timeSpanToSecond(value: TimeSpan | null): number {

    const hours = +(value?.area || 0);
    const minutes = +(value?.exchange || 0);
    const seconds = +(value?.subscriber || 0);

    const a = (hours * 3600) + (minutes * 60) + seconds;
    return a * 1000;;
  }
  get errorState(): boolean {
    return this.parts.invalid && this.touched();
  }
  parts = inject(FormBuilder).group({
    area: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2),]],
    exchange: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2),]],
    subscriber: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2),]],
  });
  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    effect(() => {
      // Read signals to trigger effect.
      this._placeholder();
      this._required();

      // Propagate state changes.
      untracked(() => this.stateChanges.next());
    });




    this.parts.statusChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.stateChanges.next();
    });

    this.parts.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
      const tel = this.parts.valid
        ? new TimeSpan(
          value.area || '',
          value.exchange || '',
          value.subscriber || '',
        )
        : null;
      this._updateValue(tel);
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this._focused()) {
      this._focused.set(true);
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched.set(true);
      this._focused.set(false);
      this.onTouched();
    }

    if (this.min && this.focused) {
      const time = { ...this.parts.value } as TimeSpan;
      const value = this.timeSpanToSecond(time);

      if (value < this.min) {
        const timeStr = this.toTimeSpan(this.min);

        this._updateValue(timeStr);
        this.parts.patchValue(timeStr, { emitEvent: false });
      }
      return;
    }

  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.input-time-span-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.subscriber.valid) {
      this._focusMonitor.focusVia(this.subscriberInput(), 'program');
    } else if (this.parts.controls.exchange.valid) {
      this._focusMonitor.focusVia(this.subscriberInput(), 'program');
    } else if (this.parts.controls.area.valid) {
      this._focusMonitor.focusVia(this.exchangeInput(), 'program');
    } else {
      this._focusMonitor.focusVia(this.areaInput(), 'program');
    }
  }

  writeValue(value: number | null): void {
    const telStr = this.toTimeSpan(value);

    this._updateValue(telStr);

    this.parts.patchValue({ ...telStr }, { emitEvent: false });

  }
  private toTimeSpan(seconds: number | null): TimeSpan {
    if (!seconds) return new TimeSpan('', '', '');
    seconds = seconds / 1000 << 0;
    const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');

    return new TimeSpan(hours, minutes, secs);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    if (isDisabled) {
      this.parts.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.parts.enable({ onlySelf: true, emitEvent: false });
    }
    this.stateChanges.next()
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (this.max) {
      const time = { ...this.parts.value } as TimeSpan;
      const value = this.timeSpanToSecond(time);
      if (value > this.max) {
        const telStr = this.toTimeSpan(this.max);
        this._updateValue(telStr);
        this.parts.patchValue(telStr, { emitEvent: false });
      }
    }


    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  private _updateValue(tel: TimeSpan | null) {
    const current = this._value();
    if (
      tel === current ||
      (tel?.area === current?.area &&
        tel?.exchange === current?.exchange &&
        tel?.subscriber === current?.subscriber)
    ) {
      return;
    }
    this._value.set(tel);
  }
}
