import moment, { Moment } from 'moment';
import { VideoWatchPercent } from '../constants';
import { defaultIfEmpty } from 'rxjs';

export function timeStringToMilliseconds(timeString: string): number {
  const timeMoment = moment(timeString, 'HH:mm:ss');

  const milliseconds = timeMoment.diff(moment().startOf('day'));

  return milliseconds;
}
export function dateToString(date: Moment | Date | string | null | undefined): string {
  if (!date) return '';
  if (typeof date === 'string') return date;

  return moment(date).toISOString(true);
}

export function watchFull(pool: Set<number>, videoDuration: number): boolean {
  return pool.size >= videoDuration * VideoWatchPercent.Full;
}

export function watchSeek(pool: Set<number>, videoDuration: number) {
  const step = (videoDuration * VideoWatchPercent.Seek) << 0;
  const epsilon = ((pool.size / step) << 0); //round up

  return pool.size == epsilon * step;
}

export interface ParseDateForRequestOptions {
  endOfDate?: boolean;
  format?: string;
  defaultIfEmptyOrNull?: any;
}

export function parseDateForRequest(date: Date | string, options: ParseDateForRequestOptions = {}): string | null {
  if (!date) return options?.defaultIfEmptyOrNull ?? null;
  if (typeof date === 'string') {
    return date;
  }

  // check date is invalid
  if (isNaN(date.getTime())) {
    return options?.defaultIfEmptyOrNull ?? null;
  }

  let _format = 'YYYY-MM-DDTHH:mm:ss';
  if (options?.format) {
    _format = options.format;
  }

  const dateMoment = moment(date);
  if (options?.endOfDate) {
    dateMoment.set({ hour: 23, minute: 59, second: 59 });
  }

  // else return iso date with format
  return dateMoment.format(_format);
}
