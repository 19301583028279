import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, forwardRef, inject, Input, signal } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipInput, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subject } from '@microsoft/signalr';
import { filterByText, filterNotInclude } from '@sod/shared/utility';
import { NgLetModule } from 'ng-let';
import { BehaviorSubject, filter, map, Observable, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'sod-input-chip',
  standalone: true,
  imports: [
    CommonModule,MatChipInput,MatChipsModule,
    CommonModule, MatInputModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatAutocompleteModule,
    MatChipsModule, NgLetModule, MatIconModule, MatCheckboxModule, MatTooltipModule
  ],
  templateUrl: './input-chip.component.html',
  styleUrl: './input-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputChipComponent),
      multi: true,
    },
  ],
})
export class InputChipComponent<T> implements ControlValueAccessor {

  @Input() placeHolder: string = '';
  @Input() set allOptions(value: T[] | null) {
    if (value)
      this._allOption = value.map(x => ({ ...x, action: 'new' }));
    this.searchControl.reset(null);
  }

  @Input() label!: keyof T;
  @Input() key!: keyof T;

  private _filterHandler = new BehaviorSubject<(T & { action: 'update' | 'new' })[]>([]);

  speakerCtrl = new FormControl<string | null>(null);
  _allOption: (T & { action: 'update'|'new' })[] = [];
  searchControl = new FormControl<string | null>(null);
  filteredOptions$ = this._filterHandler.asObservable();

  announcer = inject(LiveAnnouncer);
  destroy$ = new Subject<void>();
  private _value = signal<(T & { action: 'update' | 'new' })[]>([]);
  get value() {
    return  this._value() || []
  }

  _separatorKeysCodes: number[] = [ENTER];
  private onChange = (value: any) => { };
  private onTouched = () => { };
  protected isDisabled = false;
  constructor() {
    this.searchControl.valueChanges.pipe(
      startWith(''),
      filter(value => typeof value === 'string' || value === null),
      map(value => this.filterOptions(value))
    ).subscribe(x => this._filterHandler.next(x));
  }
  filterOptions(value: string | null): (T & { action: 'update' | 'new' })[] {
    const data = this.notInclude();
    if (!value) return this.notInclude();
    return filterByText(data, value, [this.label]);
  }

  notInclude() {
    if (this.value.length > 0)
      return filterNotInclude(this._allOption, this.value.map(x => x[this.key]), this.key);

    return this._allOption;
  }
  remove(item: any): void {
    const index = this.value.indexOf(item);

    if (index >= 0) {
      const newValue = [...this.value];
      newValue.splice(index, 1);
      this._value.set(newValue);
      // this._value.set();
      console.log(this.value);

      this.announcer.announce(`Removed ${item.firstName} ${item.lastName}`);
      this.onChange(this.value);
      this._filterHandler.next(this.notInclude());
    }

  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const newSpeaker = {
        [this.key]: null,
        [this.label]: value,
        action: 'new'
      } as T & { action: 'update' | 'new' };
      this._value.set([...this.value, newSpeaker]) ;
      this._filterHandler.next(this.notInclude());
      this.onChange(this.value);
    }
    event.chipInput!.clear();
  }
  onFocus() {
    this._filterHandler.next(this.notInclude());
  }

  selected(event: MatAutocompleteSelectedEvent, input: HTMLInputElement): void {
    this._value.set([...this.value, event.option.value]);
    input.value = '';
    this.speakerCtrl.setValue(null);
    this.onChange(this.value);
  }
  writeValue( value: T[] | null): void {
    if (value)
      this._value.set(value.map(x => ({ ...x, action: 'update' })));
    else
      this._value.set([]);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnDestroy(): void {

    this.destroy$.next();
    this.destroy$.complete();
  }
}
