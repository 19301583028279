import * as i0 from '@angular/core';
import { Directive, Input } from '@angular/core';

/**
 * @description
 *
 * The `*ngLet` directive it's a Angular structural directive for sharing data as local variable into html component template.
 *
 * @usageNotes
 *
 * ### Usage
 *
 * ```html
 * <ng-container *ngLet="(num1 + num2); let total"> <!-- single computation -->
 *    <div>
 *       1: {{ total }}
 *     </div>
 *     <div>
 *       2: {{ total }}
 *     </div>
 * </ng-container>
 * ```
 *
 * @publicApi
 */
class NgLetDirective {
  constructor(viewContainer, templateRef) {
    this.context = {
      ngLet: null,
      $implicit: null
    };
    viewContainer.createEmbeddedView(templateRef, this.context);
  }
  /**
   * @description
   *
   * The `*ngLet` directive it's a Angular structural directive for sharing data as local variable into html component template.
   *
   * @usageNotes
   *
   * ### Usage
   *
   * ```html
   * <ng-container *ngLet="(num1 + num2); let total"> <!-- single computation -->
   *    <div>
   *       1: {{ total }}
   *     </div>
   *     <div>
   *       2: {{ total }}
   *     </div>
   * </ng-container>
   * ```
   */
  set ngLet(value) {
    this.context.$implicit = this.context.ngLet = value;
  }
  /**
   * Asserts the correct type of the context for the template that `NgLet` will render.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `NgLet` structural directive renders its template with a specific context type.
   *
   * @see https://angular.dev/guide/directives/structural-directives#typing-the-directives-context
   */
  static ngTemplateContextGuard(_dir, _ctx) {
    return true;
  }
  static {
    this.ɵfac = function NgLetDirective_Factory(t) {
      return new (t || NgLetDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgLetDirective,
      selectors: [["", "ngLet", ""]],
      inputs: {
        ngLet: "ngLet"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgLetDirective, [{
    type: Directive,
    args: [{
      selector: '[ngLet]',
      standalone: true
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }, {
    type: i0.TemplateRef
  }], {
    ngLet: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();
/**
 * @deprecated import the standalone NgLetDirective
 */
const NgLetModule = NgLetDirective;

/*
 * Public API Surface of ng-let
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgLetDirective, NgLetModule };
