import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from "@angular/core";




@Directive({
    selector: 'input[appMillisecondsToTime]',
    standalone: true
})

export class TimeSpanDirective implements OnInit {
    previousValue : string = '';
    constructor(private el: ElementRef, private renderer: Renderer2) {}
    ngOnInit(): void {
    }
    private applyMask(value: string): string {
        const sanitizedValue = value.replaceAll(":", "");
        if (sanitizedValue.length <= 4 && sanitizedValue.length > 0 && sanitizedValue.length % 2 === 0) return `${value}:`;
        return value;
    }

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        if (value.length < this.previousValue.length || value.length >= 8) {
            const truncatedValue = value.slice(0, 8);
            this.renderer.setProperty(this.el.nativeElement, 'value', truncatedValue);
            this.previousValue = truncatedValue;
        } else {
            const maskedValue = this.applyMask(value);
            console.log('maskedValue',maskedValue);
            this.renderer.setProperty(this.el.nativeElement, 'value', maskedValue);
            this.previousValue = maskedValue;
        }
    }
}
