import { Injectable, Pipe } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, share, tap } from 'rxjs';
import { Store } from '@ngxs/store';
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private _cachedImages: {
    [key: number]: string;
  } = {};
  constructor(private http: HttpClient, private store: Store) { }

  public upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `api/File/Upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }


  public deleteFile(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`api/File/${id}`);
  }

  public getThumbnail(fileId: number): Observable<string> {
    if (this._cachedImages[fileId]) {
      return new Observable<string>((observer) => {
        observer.next(this._cachedImages[fileId]);
        observer.complete();
      });
    }

    return this.getFile(fileId)

  }
  public getFile(fileId: number): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/xml; charset=utf-8');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text',
    }

    return this.http.get<string>(`api/File/${fileId}/thumbnail`, requestOptions) .pipe(share(),tap(blob => {
      this._cachedImages[fileId] = blob
    }));

  }
}
