<mat-form-field class="sod-mutiple sod-flex box-control"  *ngLet="filteredOptions$ | async as filteredOptions" [matTooltip]="searchControl.value || ''" >

  <!-- Autocomplete for filtering options -->
  <mat-autocomplete #auto="matAutocomplete" [autoSelectActiveOption]="true"  [autoActiveFirstOption]="true" >
    <mat-option *ngIf="!!filteredOptions?.length">
      <mat-checkbox [formControl]="selectAllValue" (change)="selectAll($event)" (click)="$event.stopPropagation()">All</mat-checkbox>
    </mat-option>
    <mat-option *ngFor="let option of filteredOptions || []">
      <mat-checkbox [checked]="option.selected" (change)="toggleSelection(option)" (click)="$event.stopPropagation()">
        {{ option[labelSearch[0]] }}
      </mat-checkbox>
    </mat-option>
  </mat-autocomplete>


  <!-- Input field for typing and filtering with autocomplete -->
  <input type="text" class="sod-input-field" matInput [formControl]="searchControl" [matAutocomplete]="auto" #trigger="matAutocompleteTrigger"
    [placeholder]="placeholder">
  <mat-icon matSuffix [ngClass]="{'disabled':disabled}">keyboard_arrow_down</mat-icon>
</mat-form-field>
