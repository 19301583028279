import { Directive, ElementRef, Input } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl, Validators } from '@angular/forms';

@Directive({
  selector: '[controlRequired]'
})
export class ControlRequiredDirective {

  @Input("controlRequired") controlName: string = '';

  constructor(private el: ElementRef, private formGroupDirective: FormGroupDirective) { }

  ngOnInit() {
    this.checkAndAddRequiredAttribute();
  }

  private checkAndAddRequiredAttribute() {
    const formControl = this.FormControl();
    if (formControl && formControl.validator) {
      const validators = formControl.validator({} as AbstractControl);


      if (validators && validators["required"]) {
        this.addRequiredAttribute();
      }
    }
  }

  private addRequiredAttribute() {
    const nativeElement = this.el.nativeElement;
    nativeElement.setAttribute && nativeElement.setAttribute('aaaaaaa', 'true');
  }
  FormGroup() { return this.formGroupDirective.form; }

  FormControl(): AbstractControl | null {
    const formGroup = this.FormGroup()
    const formControl = this.controlName;

    return formGroup ? formGroup.get(formControl) : null;
  }
}
