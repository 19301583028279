<mat-form-field class="sod-mutiple w-100 sod-flex box-control" *ngLet="filteredOptions$ | async as filteredOptions" >
  <mat-autocomplete #auto="matAutocomplete"
  [autoSelectActiveOption]="!freeText()"
  [autoActiveFirstOption]="!freeText()"

  (optionSelected)="onOptionSelected($event)" >
    <mat-option *ngFor="let option of filteredOptions || []" matTooltip="{{ option[label] }}" [value]="option[key]" >
      <ng-container [ngTemplateOutlet]="customLabel || defaultTemplate" [ngTemplateOutletContext]="{ option: option }"></ng-container>
    </mat-option>
  </mat-autocomplete>

  <!-- Define the custom template outside mat-option -->
  <ng-template #defaultTemplate let-option="option">
    <span class="select-item-content">{{ option[labelSearch[0]] }}</span>
  </ng-template>
  <!-- Hidden container where the template will be rendered -->
  <ng-template #templateContainer></ng-template>

  <input type="text" class="sod-input-field input-slot" [placeholder]="placeholder || ''"
  matInput [matTooltip]="searchControl.value || ''"
  [formControl]="searchControl"
  [matAutocomplete]="auto"
  #txtVal
  (focus)="onFocus()"
  (blur)="onBlur()"
  #trigger="matAutocompleteTrigger">

  <mat-icon *ngIf="!value()" class="extend-icon" matSuffix>search</mat-icon>
  <button *ngIf="value()" matSuffix mat-icon-button aria-label="Clear" class="clear icon" (click)="clear()">
    <mat-icon class="icon extend-icon">close</mat-icon>
  </button>
    <!-- <mat-error *ngIf="nameControl.errors?.required">name is required</mat-error> -->
</mat-form-field>
