import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'sodTake',
  standalone: true,
})
export class TakePipe<T> implements PipeTransform {

  transform(value: T[], take: number | null): T[] {
   if(!value || !take) return value || [];
   return value.slice(0, take);
  }

}
