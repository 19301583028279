import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, EventEmitter, forwardRef, Input, NgModule, Output, signal } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClickOutSideDirective } from '@sod/shared/directives/click-out-side.directive';
import { IAutoCompleteScrollEvent, OptionsScrollDirective } from '@sod/shared/directives/options-scroll.directive';
import { NgLetModule } from 'ng-let';
import { debounceTime, filter, finalize, map, Observable, startWith, tap } from 'rxjs';
import { PageContentDirective } from "../../directives/pageContent.directive";
import { SelectAutocompleteComponent } from '../select-autocomplete/select-autocomplete.component';

export interface ScrollChange {
  search?: string | null,
  page: number,
  pageSize: number,
}
@Component({
  selector: 'sod-select-scroll',
  standalone: true,
  imports: [
    CommonModule, MatInputModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatAutocompleteModule,
    MatChipsModule, NgLetModule, MatIconModule, MatCheckboxModule, MatTooltipModule,
    OptionsScrollDirective, ClickOutSideDirective,
    PageContentDirective
],
  templateUrl: './select-scroll.component.html',
  styleUrl: './select-scroll.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectScrollComponent),
      multi: true,
    },
  ],
})
export class SelectScrollComponent<T> extends SelectAutocompleteComponent<T> {

  @Output() onScrollChange = new EventEmitter<ScrollChange>();

  @Input() override set allOptions(value: Observable<T[]> | null) {
    if (value) {

      this._options$ = value;
      this.filteredOptions$ = this._options$.pipe(
        map(data => [... this._allOption, ...data]),
        tap(res => this._allOption = res)),
        finalize(() => { this.toast.disableLoading(false); });
    }

    this.searchControl.setValue(null, { emitEvent: false });
    this.updateDisplay$.next();
  }
  protected override onChange = (value: any) => { };
  protected override onTouched = () => { };
  private _nextSeminarPage = signal(1);
  override searchControl = new FormControl<string | null>(null);
  curentPage = computed(() => this._nextSeminarPage());
  constructor() {
     super();
    this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      filter(q => typeof q === "string"),
    ).subscribe((text) => {
      this._allOption = [];
      this.toast.disableLoading(true);
      this._nextSeminarPage.set(1);
      this.onScrollChange.emit({ page: 1, pageSize: 20, search: text })
    })
    effect(() => {
      const page = this._nextSeminarPage();
      this.onScrollChange.emit({ page, pageSize: 20, search: this.searchControl.value })
    })

  }
  onOptionScrollSelected(e: MatAutocompleteSelectedEvent) {
    this._value = e.option.value;
    this.updateDisplay$.next();
    this.onChange(this.value);
    this.onSelected.emit(this.value());

  }
  override ngOnInit(): void {
    super.ngOnInit();

  }
  override onFocus() {
    this._preValue = this.value();
    if (!this._allOption || this._allOption.length == 0) {

      this.toast.disableLoading(true);
      this._nextSeminarPage.set(1);
    }
  }
  onOptionScroll(e: IAutoCompleteScrollEvent) {
    this._nextSeminarPage.update(p => p + 1);
  }


}

@NgModule({
  exports: [SelectScrollComponent],
  imports: [
    CommonModule, MatInputModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatAutocompleteModule,
    MatChipsModule, NgLetModule, MatIconModule, MatCheckboxModule, MatTooltipModule,
    SelectScrollComponent
  ],
})
export class SelectScrollModule { }
