<mat-form-field class="sod-mutiple sod-flex w-100 sod-chip">
  <div class="sod-follow">
  <mat-chip-grid #chipGrid class="w-100 sod-flex-1">
    <mat-chip-row *ngFor="let item of value" (removed)="remove(item)" [aria-description]="'press enter to edit ' + item[label]">
      {{ item[label] }}
      <button matChipRemove [attr.aria-label]="'remove ' + item">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input [placeholder]="placeHolder" #speakerInput [formControl]="searchControl"
  (focus)="onFocus()"
  [matChipInputFor]="chipGrid"
  [matAutocomplete]="auto"
  [matChipInputSeparatorKeyCodes]="_separatorKeysCodes"
  (matChipInputTokenEnd)="add($event)" />
  </div>
  <mat-autocomplete #auto="matAutocomplete"
  (optionSelected)="selected($event, speakerInput)">
    @for (item of filteredOptions$ | async; track item) {
    <mat-option [value]="item">{{ item[label] }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
