import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { filterByText } from '@sod/shared/utility';
import { NgLetModule } from 'ng-let';
import { filter, map, Observable, startWith, Subject, tap } from 'rxjs';

@Component({
  selector: 'sod-select-mutiple',
  standalone: true,
  imports: [
    CommonModule, MatInputModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatAutocompleteModule,
    MatChipsModule, NgLetModule, MatIconModule, MatCheckboxModule, MatTooltipModule
  ],
  templateUrl: './select-mutiple.component.html',
  styleUrls: ['./select-mutiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMutipleComponent),
      multi: true,
    },
  ],
})
export class SelectMutipleComponent<T> implements OnInit, ControlValueAccessor {

  @Input() set allOptions(value: T[] | null) {
    if (value)
      this._allOption = value.map(x => ({ ...x, selected: false }));
    this.searchControl.reset(null);
    this.updateDisplay$.next();
    // this.onChange(this._allOption.filter(option => option.selected));
  }
  @Input() set label(value: keyof T) {
    if (value) this._lablelSearch = [value]
  };
  @Input() set labelSearch(value: (keyof T)[]) {
    if (value) {
      this._lablelSearch = value;
    }
  };
  @Input() key!: keyof T;
  @Input() placeholder: string = '';
  selectAllValue = new FormControl<boolean | null>(null);
  searchControl = new FormControl<string | null>(null);
  _allOption: (T & { selected: boolean })[] = [];
  _values: (T[keyof T][])[] = [];
  _lablelSearch: (keyof T)[] = [];
  filteredOptions$: Observable<(T & { selected: boolean })[]>;
  onSelect$ = new Subject<T & { selected: boolean }>();
  updateDisplay$ = new Subject<void>();
  get labelSearch() {
    return this._lablelSearch
  }
  private onChange = (value: any) => { };
  private onTouched = () => { };
  private isDisabled = false;
  public get disabled() {
    return this.isDisabled;
  }
  constructor() {
    this.filteredOptions$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value))
    );
    this.onSelect$.pipe(takeUntilDestroyed()).subscribe(x => {
      const allSelect = this._allOption.every(x => x.selected);
      if (allSelect) {
        this.selectAllValue.setValue(true, { emitEvent: false });
      } else if (this.selectAllValue.value === true) {
        this.selectAllValue.setValue(null, { emitEvent: false });
      }
      this.onChange(this.selectedKeys);
    });
  }

  get selected() {
    return (this._allOption || []).filter(x => x.selected) || [];
  }

  filterOptions(value: string | null): (T & { selected: boolean })[] {
    if (!value) return this._allOption;
    return filterByText(this._allOption, value, this._lablelSearch)
  }

  ngOnInit(): void {
    this.updateDisplay$.pipe(
      filter(() => !!this._allOption && !!this._values && this._allOption.length > 0 && this._values.length > 0))
      .subscribe(() => {
        this.searchControl.reset(null);
        this._allOption.forEach(element => {
          if (this._values.some(x => x === element[this.key]))
            element.selected = true;
          else
            element.selected = false;
        });
        this.bindingData();
      })

  }
  bindingData() {
    const text = this.getTextDisplay();
    this.searchControl.setValue(text, { emitEvent: false });

  }
  getTextDisplay() {
    const selected = this.selected;
    if (!selected || selected.length == 0) return '';
    if (selected.length == 1) return selected[0][this._lablelSearch[0]] as string;
    return selected.map(x => x[this._lablelSearch[0]]).join(', ');
  }
  removeOption(option: T & { selected: boolean }) {
    option.selected = false;
    this.onSelect$.next(option);
    this.onChange(this.selectedKeys);
  }

   toggleSelection  (data: T & { selected: boolean }) {
    data.selected = !data.selected;
    this.onSelect$.next(data);
    this.onChange(this.selectedKeys);
    this.bindingData();
  };

  selectAll(e: MatCheckboxChange) {
    if (e.checked) {
      this._allOption.forEach(x => x.selected = true);
    } else {
      this._allOption.forEach(x => x.selected = false);
    }
    this.onChange(this.selectedKeys);
    this.bindingData();
  }

  get selectedKeys(): T[keyof T][] | null {
    return this.selected.map(option => option[this.key]);
  }

  // ControlValueAccessor Implementation
  writeValue(values: (T[keyof T][])[]): void {
    this._values = values || [];
    this.searchControl.setValue('', { emitEvent: false });
    this.updateDisplay$.next();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled)
      this.searchControl.disable()
    else
      this.searchControl.enable()
  }
}
