import { Pipe, type PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'sodTimeSpan',
  standalone: true,
})
export class TimeSpanPipe implements PipeTransform {

  transform(value?: number | null, ...args: string[]): string {
    if (!value || value === 0) return '00:00:00';
    const format = args?.length > 0 ? args[0] ?? 'HH:mm:ss' : 'HH:mm:ss';

    value = value >0 ? value : 0;
    if(args?.length)
      return moment.utc(+(value ?? 0)).format(format);
    return moment.utc(+(value ?? 0)).format('HH:mm:ss');
  }
}
