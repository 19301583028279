<div class="wapper-rating">
  <mat-icon
    [ngClass]="{ rated: rating >= i }"
    aria-hidden="false"
    aria-label="Example home icon"
    fontIcon="star"
    *ngFor="let i of reverseRange(10, 1)"
    (click)="setRating(i)"
  ></mat-icon>
</div>
