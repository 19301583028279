import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'sod-start-rating',
  templateUrl: './start-rating.component.html',
  styleUrl: './start-rating.component.scss'
})
export class StartRatingComponent {
  @Input() rating: number = 0;
  @Output() onRating: EventEmitter<number> = new EventEmitter();
  setRating(value: number){
    this.rating = value;
    this.onRating.emit(value)
  }
  reverseRange(start: number, end: number): number[] {
    const result = [];
    for (let i = start; i >= end; i--) {
      result.push(i);
    }
    return result;
  }
}
