import { Directive } from '@angular/core';

@Directive({
  selector: '[appChineseInput]'
})
export class ChineseInputDirective {

  constructor() { }

}
