import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent {
  autoComplete: MatAutocomplete;
  scrollEvent: Event;
  load2End: boolean;
}

@Directive({
  selector: 'mat-autocomplete[optionsScroll]',
  standalone: true
})
export class OptionsScrollDirective implements OnDestroy {

  @Input() thresholdPercent = .95;
  @Input() reset = true;
  @Output('optionsScroll') scroll = new EventEmitter<IAutoCompleteScrollEvent>();
  @Output() onClicked = new EventEmitter<any>();
  _onDestroy = new Subject();
  private _page = new Set();

  constructor(public autoComplete: MatAutocomplete) {
    this.autoComplete.opened.pipe(
      tap(() => {
        // Note: When autocomplete raises opened, panel is not yet created (by Overlay)
        // Note: The panel will be available on next tick
        // Note: The panel wil NOT open if there are no options to display

        setTimeout(() => {
          // Note: remove listner just for safety, in case the close event is skipped.
          this.removeScrollEventListener();
          this.autoComplete.panel.nativeElement
            .addEventListener('scroll', this.onScroll.bind(this))
        });
      }),
      takeUntil(this._onDestroy)).subscribe();

    this.autoComplete.closed.pipe(
      tap(() => this.removeScrollEventListener()),
      takeUntil(this._onDestroy)).subscribe();
  }

  private removeScrollEventListener() {
    this.autoComplete?.panel?.nativeElement
      .removeEventListener('scroll', this.onScroll);
  }

  ngOnDestroy() {
    this._onDestroy.next(0);
    this._onDestroy.complete();

    this.removeScrollEventListener();
  }

  onScroll(event: Event) {

    if (this.thresholdPercent === undefined) {
      this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event, load2End: false });
    } else {
      const threshold = this.thresholdPercent * 100 * (<HTMLInputElement>event.target).scrollHeight / 100;
      const current = (<HTMLInputElement>event.target).scrollTop + (<HTMLInputElement>event.target).clientHeight;
      if (this.reset){
        this._page.clear();
      }
      // console.log(`scroll ${current}, threshold: ${threshold}`)
      if (current > threshold && !this._page.has(threshold << 0)) {
        this._page.add(threshold <<0);
        this.reset = false;
        //console.log('load next page');
        this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event, load2End: true });
      }
    }
  }
}
