import {
  Directive,
  inject,
  Input,
  SimpleChanges,
} from '@angular/core';
import { MatOption, MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Directive({ selector: 'mat-option[selectAll]' })
export class OptionAllSelectDirective {
  @Input({ required: true }) allValues: any[] = [];
  private _matSelect = inject(MatSelect);
  private _matOption = inject(MatOption);

  private _subscriptions: Subscription[] = [];

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterViewInit(): void {
    const parentSelect = this._matSelect;
    const parentFormControl = parentSelect.ngControl.control;

    // For changing other option selection based on select all
    this._subscriptions.push(
      parentSelect.optionSelectionChanges.subscribe((v) => {
        if (v.isUserInput && v.source.value !== this._matOption.value) {
          if (!v.source.selected) {
            this._matOption.deselect(false);
          } else {
            if (parentFormControl?.value.length === this.allValues.length) {
              this._matOption.select(false);
            }
          }
        }
        else {
          if (v.isUserInput) {
            if (v.source.selected) {
                parentFormControl?.setValue(this.allValues);
                this._matOption.select(false);
            } else {
                parentFormControl?.setValue([]);
                this._matOption.deselect(false);
            }
        }
        }
      })
    );

    setTimeout(() => {
      // auto select all if all values are selected
      if (parentFormControl?.value.length > 0 && parentFormControl?.value.length === this.allValues.length) {
        this._matOption.select(true);
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }
}
