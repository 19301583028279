
<mat-form-field class="sod-mutiple w-100 sod-flex box-control" *ngLet="filteredOptions$ | async as filteredOptions">
  <mat-autocomplete #auto="matAutocomplete" [autoSelectActiveOption]="!freeText()"
  [autoActiveFirstOption]="!freeText()"
  [reset]="curentPage() === 1"
  (optionsScroll)="onOptionScroll($event)"
  (optionSelected)="onOptionSelected($event)"
  >
    <mat-option *ngFor="let option of filteredOptions || []" matTooltip="{{ option[label] }}" [value]="option[key]">
    <ng-container [ngTemplateOutlet]="customLabel || defaultTemplate" [ngTemplateOutletContext]="{ option: option }"></ng-container>
    </mat-option>
  </mat-autocomplete>
  <!-- Define the custom template outside mat-option -->
  <ng-template #defaultTemplate let-option="option">
    <span class="custom-content">{{ option[labelSearch[0]] }}</span>
  </ng-template>
  <!-- Hidden container where the template will be rendered -->
  <ng-container  #templateContainer></ng-container>
  <input type="text" class="sod-input-field input-slot" [placeholder]="placeholder || ''" matInput [matTooltip]="searchControl.value || ''"
  [formControl]="searchControl"
  [matAutocomplete]="auto"
  (focus)="onFocus()"
  (blur)="onBlur()"
   #trigger="matAutocompleteTrigger">

  <mat-icon *ngIf="!hasData()" matSuffix>search</mat-icon>
  <button *ngIf="hasData()" matSuffix mat-icon-button aria-label="Clear" class="clear icon" (click)="clear()">
    <mat-icon class="icon">close</mat-icon>
  </button>
  <!-- <mat-error *ngIf="nameControl.errors?.required">name is required</mat-error> -->
</mat-form-field>

